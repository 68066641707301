<template>
  <div class="app-container">
    <div class="wrap">
      <navBar :title="title" @handleGoBack="handleGoBack"></navBar>
      <div class="add-form">
        <van-form validate-first @failed="onFailed" @submit="onSubmit">
          <van-field
              v-model="formInline.title"
              label="实验标题"
              placeholder="请输入"
              :required="true"
              :rules="[{ required: true, message: '请输实验标题' }]"
          />

          <van-field
              readonly
              clickable
              v-model="formInline.whName"
              label="出库仓库"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择出库仓库' }]"
              @click="showPickerWarehouse = true"
          />

          <van-field
              readonly
              clickable
              v-model="formInline.reportStr"
              label="填报人"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择填报人' }]"
              @click="showPop= true"
          />

          <van-field
              readonly
              v-model="formInline.reportDate"
              label="填报日期"
              placeholder="点击选择日期"
              :required="true"
              :rules="[{ required: true, message: '请选择填报日期' }]"
          />

          <van-field
              readonly
              clickable
              v-model="formInline.experimentStr"
              label="实验人"
              placeholder="请选择"
              v-if="type == 'detail'||type == 'writeResult'"
              :required="type == 'writeResult'"
              :rules="type == 'writeResult'?[{ required: true, message: '请选择实验人' }]:[]"
              @click="showPop2= true"
          />

          <van-field
              readonly
              clickable
              :value="formInline.experimentDate"
              label="实验日期"
              placeholder="点击选择日期"
              v-if="type == 'detail'||type == 'writeResult'"
              :required="type == 'writeResult'"
              :rules="type == 'writeResult'?[{ required: true, message: '请选择实验日期' }]:[]"
              @click="showPickerApply2 = true"
          />

          <van-field
              readonly
              clickable
              v-model="formInline.isApproveStr"
              label="是否审批"
              placeholder="请选择"
              :required="true"
              :rules="[{ required: true, message: '请选择是否审批' }]"
              @click="showIsApprove= true"
              v-if="type != 'writeResult'"
          />
          <van-popup v-model="showIsApprove" position="bottom">
            <van-picker
                show-toolbar
                :columns="isApproveOptions"
                value-key="dictLabel"
                @confirm="
                onConfirm($event, 'isApprove', 'isApproveStr', 'dictLabel', 'showIsApprove')
              "
                @cancel="showIsApprove = false"
            />
          </van-popup>

          <textAreaInput :remark="formInline.remark" @change="handleContentChange" :title="'备注'"/>

          <div v-for="(item, index) in dataList" :key="index">
            <div class="flex-spa-bet list-tit-wrap">
              <div class="left-tit">{{ item.materialName || '--' }}（{{ item.materialCode || '--' }}）</div>
              <van-icon name="delete-o" @click="handleDelete(index)"/>
            </div>

            <van-field
                v-model="item.materialSpecification"
                label="规格型号"
                disabled
            />

            <van-field
                v-model="item.materialUnit"
                label="单位"
                disabled
            />

            <van-field
                v-model="item.bathNumber"
                label="批次"
                disabled
            />

            <van-field
                v-model="item.materialNum"
                label="库存数量"
                disabled
                v-if="type == 'add'"
            />

            <van-field
                v-model="item.costPrice"
                label="入库价"
                disabled
            />

            <van-field
                v-model="item.collectNum"
                label="领用数量"
                placeholder="请输入"
                :disabled="type != 'add'"
                :required="true"
                :rules="[{ required: true, message: '请输入领用数量' }]"
            />

            <van-field
                v-model="item.experimentNum"
                label="实验数量"
                placeholder="请输入"
                :disabled="type == 'detail'"
                v-if="type == 'detail' || type == 'writeResult'"
                :required="type == 'writeResult'"
                :rules="type == 'writeResult'?[{ required: true, message: '请输入实验数量' }]:[]"
                @input="calLossRate(item)"
            />

            <van-field
                v-model="item.lossNum"
                label="实验报损量"
                placeholder="请输入"
                :disabled="type == 'detail'"
                v-if="type == 'detail' || type == 'writeResult'"
                :required="type == 'writeResult'"
                :rules="type == 'writeResult'?[{ required: true, message: '请输入实验报损量' }]:[]"
                @input="calLossRate(item)"
            />

            <van-field
                v-model="item.expireLossNum"
                label="过期报损量"
                placeholder="请输入"
                :disabled="type == 'detail'"
                v-if="type == 'detail' || type == 'writeResult'"
                :required="type == 'writeResult'"
                :rules="type == 'writeResult'?[{ required: true, message: '请输入过期报损量' }]:[]"
                @input="calLossRate(item)"
            />

            <van-field
                v-model="item.otherLossNum"
                label="其他报损量"
                placeholder="请输入"
                :disabled="type == 'detail'"
                v-if="type == 'detail' || type == 'writeResult'"
                :required="type == 'writeResult'"
                :rules="type == 'writeResult'?[{ required: true, message: '请输入其他报损量' }]:[]"
                @input="calLossRate(item)"
            />

            <van-field
                v-model="item.lossRate"
                label="实验耗损率(%)"
                placeholder="请输入"
                disabled
                v-if="type == 'detail' || type == 'writeResult'"
            />

            <van-field
                v-model="item.expireLossRate"
                label="过期耗损率(%)"
                placeholder="请输入"
                disabled
                v-if="type == 'detail' || type == 'writeResult'"
            />

            <van-field
                v-model="item.otherLossRate"
                label="其他耗损率(%)"
                placeholder="请输入"
                disabled
                v-if="type == 'detail' || type == 'writeResult'"
            />

            <van-field
                v-model="item.totalLossRate"
                label="耗损率(总)"
                placeholder="请输入"
                disabled
                v-if="type == 'detail' || type == 'writeResult'"
            />

            <!--<van-field
                    v-model="item.overflowNum"
                    label="报溢数量"
                    placeholder="请输入"
                    :disabled="type == 'detail'"
                    v-if="false && type == 'detail' || type == 'writeResult'"
                    :required="type == 'writeResult'"
                    :rules="type == 'writeResult'?[{ required: true, message: '请输入报溢数量' }]:[]"
                    @input="calLossRate(item)"
            />-->

            <van-field
                v-model="item.remark"
                label="备注"
                placeholder="请输入"
            />

          </div>
          <div class="add-btn flex-hor-ver-cen" @click="handleAdd">
            <van-icon name="add-o" class="mrg-r-20"/>
            添加物料
          </div>

          <AppendixVue :businessType="uploadParams.businessType" :needUpload="false" v-if="type != 'add'"/>

          <div class="line"></div>
          <van-field name="uploader" label="附件" v-if="type != 'detail' && isShowAuth">
            <template #input>
              <van-uploader v-model="uploader" :after-read="afterRead"/>
            </template>
          </van-field>

          <van-button class="btn" block type="info" native-type="submit" v-if="type != 'detail'">提交
          </van-button>
        </van-form>
      </div>
    </div>

    <van-popup v-model="showPickerApply" style="width: 70%">
      <van-datetime-picker
          v-model="currentDate"
          type="datetime"
          title="请选择时间"
          @confirm="onConfirmTime"
          @cancel="showPickerApply = false"
      />
    </van-popup>

    <van-popup v-model="showPickerApply2" style="width: 70%">
      <van-datetime-picker
          v-model="currentDate2"
          type="datetime"
          title="请选择时间"
          @confirm="onConfirmTime2"
          @cancel="showPickerApply2 = false"
      />
    </van-popup>

    <WarehouseTree :showPop="showPickerWarehouse" @handleClose="handleCloseWarehouse"/>

    <SelectOneUser :showPop="showPop" @popSubmit="popSubmit" @handleClose="handleCloseUser"/>

    <SelectOneUser2 :showPop="showPop2" @popSubmit="popSubmit2" @handleClose="handleCloseUser2"/>

    <MaterialStock :showDialog="showPickerMaterial" :whId="formInline.whId" @handleClose="handleCloseMaterial"
                   @submitData="submitMaterial"/>
  </div>
</template>
<script>
import {addData, findOne, updateData, writeResult} from '@/api/inventory/experiment';
import AppendixVue from '@/views/components/crm/Appendix.vue';
import WarehouseTree from '@/components/warehouseTree'
import SelectOneUser from '@/components/selectOneUser/index'
import SelectOneUser2 from '@/components/selectOneUser/index'
import MaterialStock from "@/components/materialStock/index";
import {changeToCurrentDay, getCurrentMonthDay2} from "@/utils/ruoyi";
import {submiteUpload} from "@/api/system/config";

export default {
  components: {MaterialStock, WarehouseTree, AppendixVue, SelectOneUser, SelectOneUser2},
  data() {
    return {
      formInline: {},
      currentDate: new Date(),
      currentDate2: new Date(),
      title: '新建实验',
      id: this.$route.query.id,
      type: this.$route.query.type,
      uploader: [],
      uploadParams: {
        businessType: 'inventory_experiment',
        businessId: undefined,
        file: null,
      },
      showPickerWarehouse: false,
      showPickerMaterial: false,
      showPop: false,
      showPop2: false,
      showPickerApply: false,
      showPickerApply2: false,
      showIsApprove: false,
      minDate: new Date(2010, 0, 1),
      //物料数组
      dataList: [],
      //是否审批
      isApproveOptions: [
        {'dictLabel': '否', 'dictValue': false},
        {'dictLabel': '是', 'dictValue': true}
      ],
      isShowAuth: localStorage.getItem('isShowAuth') === '1'
    };
  },

  mounted() {
    //页面获取焦点后，定位到页面最顶部
    scrollTo(0, 0)
    //回显
    this.findDetail()
  },

  methods: {
    handleGoBack() {
      this.$router.replace('/inventory/inventoryExperiment');
      this.$router.go(-1)
    },

    //回显
    async findDetail() {
      if (this.type == 'add') {
        this.formInline = {
          isApprove: false,
          isApproveStr: '否',
          reportDate: getCurrentMonthDay2()
        };
        this.title = '新建实验';
      } else {
        let itemDetail = await findOne(this.id);
        itemDetail = itemDetail.data
        itemDetail.isApproveStr = itemDetail.isApprove ? '是' : '否'
        this.formInline = itemDetail;
        this.dataList = itemDetail.experimentDetailList
        if (itemDetail && this.type == 'edit') {
          this.title = '编辑实验';
        } else if (this.type == 'writeResult') {
          this.title = '填报实验结果';
        } else {
          this.title = '实验详情';
        }
      }
    },

    //关闭仓库弹窗
    handleCloseWarehouse(resultObj) {
      if (resultObj) {
        this.whId = resultObj.id
        this.formInline.whId = resultObj.id
        this.formInline.whName = resultObj.name
      }
      this.showPickerWarehouse = false
    },

    //日期提交 填报
    onConfirmTime(value) {
      this.formInline.reportDate = changeToCurrentDay(value);
      this.showPickerApply = false;
    },

    //日期提交 实验
    onConfirmTime2(value) {
      this.formInline.experimentDate = changeToCurrentDay(value);
      this.showPickerApply2 = false;
    },

    //选择人提交 填报
    popSubmit(activeId, names) {
      this.showPop = false
      this.formInline.reportId = activeId
      this.formInline.reportStr = names
    },

    //关闭选择人
    handleCloseUser() {
      this.showPop = false
    },

    //选择人提交 实验
    popSubmit2(activeId, names) {
      this.showPop2 = false
      this.formInline.experimentId = activeId
      this.formInline.experimentStr = names
    },

    //关闭选择人 实验
    handleCloseUser2() {
      this.showPop2 = false
    },

    //是否审批确认
    async onConfirm(event, key, keyName, eventKey, visible) {
      this.formInline[key] = event['dictValue'];
      this.formInline[keyName] = event[eventKey];
      this[visible] = false;
    },

    //备注
    handleContentChange(val) {
      this.formInline.remark = val;
    },

    //删除节点
    handleDelete(index) {
      this.dataList.splice(index, 1);
    },

    //添加节点
    handleAdd() {
      if (this.formInline.whId) {
        this.showPickerMaterial = true
      } else {
        this.$toast.fail('请先选择出库仓库');
      }
    },

    //关闭物料弹窗
    handleCloseMaterial() {
      this.showPickerMaterial = false
    },

    //物料提交
    submitMaterial(obj) {
      let oldMaterial = this.dataList.find(item => item.materialId == obj.id)
      if (!oldMaterial) {
        this.dataList.push({
          materialId: obj.materialId,
          materialName: obj.materialName,
          materialCode: obj.materialCode,
          materialSpecification: obj.materialSpecification,
          materialUnit: obj.materialUnit,
          bathNumber: obj.bathNumber,
          materialNum: obj.materialNum,
          costPrice: obj.costPrice,
          collectNum: 0,
          remark: ''
        });
      }

      this.handleCloseMaterial()
    },

    //计算耗损率
    calLossRate(row) {
      let collectNum = row.collectNum
      let lossNum = row.lossNum
      let expireLossNum = row.expireLossNum
      let otherLossNum = row.otherLossNum
      if (collectNum == undefined || collectNum == null || collectNum == 0) {
        collectNum = 1
      }
      if (lossNum == undefined || lossNum == null) {
        lossNum = 0
      }
      if (expireLossNum == undefined || expireLossNum == null) {
        expireLossNum = 0
      }
      if (otherLossNum == undefined || otherLossNum == null) {
        otherLossNum = 0
      }

      row.lossRate = (Number(lossNum ? lossNum : 0) * 100 / Number(collectNum)).toFixed(3)
      row.expireLossRate = (Number(expireLossNum ? expireLossNum : 0) * 100 / Number(collectNum)).toFixed(3)
      row.otherLossRate = (Number(otherLossNum ? otherLossNum : 0) * 100 / Number(collectNum)).toFixed(3)
      row.totalLossRate = ((Number(expireLossNum ? expireLossNum : 0) + Number(lossNum ? lossNum : 0) + Number(otherLossNum ? otherLossNum : 0)) * 100 / Number(collectNum)).toFixed(3)
    },

    async onSubmit() {
      if (this.dataList.length == 0) {
        this.$toast.fail('请添加出库物料');
        return;
      }

      let flag = true
      this.dataList.forEach(item => {
        delete item.createBy
        delete item.createTime
        delete item.updateBy
        delete item.updateTime
        delete item.companyId

        if (this.type != 'writeResult') {
          //新增 修改
          if (item.collectNum == null || item.collectNum == undefined || item.collectNum == '' || Number(item.collectNum) <= 0) {
            this.$message({
              type: 'error',
              message: '领用量必须大于0！'
            })
            flag = false
            return
          }
          if (Number(item.materialNum) < Number(item.collectNum)) {
            this.$toast.fail('领用数量不能大于库存数量！')
            flag = false
            return
          }
        } else {
          //实验结果
          if (item.experimentNum == null || item.experimentNum == undefined || item.experimentNum == '' || Number(item.experimentNum) < 0) {
            this.$message({
              type: 'error',
              message: '实验量必须大于等于0！'
            })
            flag = false
            return
          }
          if (item.lossNum == null || item.lossNum == undefined || item.lossNum == '' || Number(item.lossNum) < 0) {
            this.$message({
              type: 'error',
              message: '实验报损量必须大于等于0！'
            })
            flag = false
            return
          }
          if (item.expireLossNum == null || item.expireLossNum == undefined || item.expireLossNum == '' || Number(item.expireLossNum) < 0) {
            this.$message({
              type: 'error',
              message: '过期报损量必须大于等于0！'
            })
            flag = false
            return
          }
          if (item.otherLossNum == null || item.otherLossNum == undefined || item.otherLossNum == '' || Number(item.otherLossNum) < 0) {
            this.$message({
              type: 'error',
              message: '其他报损量必须大于等于0！'
            })
            flag = false
            return
          }
          if (Number(item.lossNum) > Number(item.collectNum)) {
            this.$toast.fail('实验报损量不能大于领用量！')
            flag = false
            return
          }
          if (Number(item.expireLossNum) > Number(item.collectNum)) {
            this.$toast.fail('过期报损量不能大于领用量！')
            flag = false
            return
          }
          if (Number(item.otherLossNum) > Number(item.collectNum)) {
            this.$toast.fail('其他报损量不能大于领用量！')
            flag = false
            return
          }
          if (Number(item.experimentNum) + Number(item.lossNum) + Number(item.expireLossNum) + Number(item.otherLossNum) != Number(item.collectNum)) {
            this.$toast.fail('实验量+所有报损量必须等于领用量！')
            flag = false
            return
          }
        }
      })

      if (!flag) {
        return
      }

      this.formInline.experimentDetailList = this.dataList
      let result
      if (this.type == 'writeResult') {
        result = await writeResult(this.formInline)
      } else if (this.type == 'edit') {
        result = await updateData(this.formInline)
      } else {
        result = await addData(this.formInline)
      }
      if (result.code == 200) {
        this.$toast.success('操作成功');
        if (this.uploadParams.file) {
          this.uploadParams.businessId = result.data
          setTimeout(async () => {
            await submiteUpload(this.uploadParams);
          }, 1000);
        }
        this.handleGoBack();
      }
    },

    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },

    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.uploadParams.file = file.file;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-tit {
  padding: 20px 30px;
  font-size: 24px;
  color: #666666;
}

.btn {
  width: 710px;
  height: 100px;
  background: #0091ff;
  border-radius: 6px 6px 6px 6px;
  margin: 118px auto 0;
  font-size: 38px;
}

.list-tit-wrap {
  padding: 24px 30px;
  font-size: 26px;
  color: #333333;
}

.add-btn {
  width: 750px;
  height: 110px;
  background: #ffffff;
  margin-bottom: 20px;
  text-align: center;
  line-height: 110px;
  font-size: 30px;
  color: #54afff;
  cursor: pointer;
}

// 导航样式 --start
::v-deep .van-hairline--bottom::after {
  border-bottom: none;
}

::v-deep .van-nav-bar .van-icon {
  color: #000;
}

::v-deep .van-nav-bar__content {
  height: 100px;
  line-height: 100px;
}

::v-deep .van-nav-bar__arrow,
::v-deep .van-nav-bar .van-icon {
  font-size: 32px;
}

// 导航样式 --end
</style>
